import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <p>MADE WITH ❤️🖤💚 IN AFRICA &copy; {new Date().getFullYear()} GREEN FIELDS LIMITED.</p>
    </footer>
  );
}

export default Footer;
